import type { PropsWithChildren } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "wouter";

import Footer from "~/components/layouts/Footer";
import Layout from "~/components/layouts/Layout";
import { APP_ROUTES } from "~/constants";
import useUser from "~/hooks/auth/useUser";

const LoadingLayout = (props: PropsWithChildren) => {
	const { children } = props;

	const { user } = useUser();

	if (user) {
		return (
			<Layout maxWidth="xs">
				<Stack spacing={2}>
					<Skeleton variant="rounded" sx={{ height: 60, borderRadius: 3 }} />
					<Skeleton variant="rounded" sx={{ height: 60, borderRadius: 3 }} />
					<Skeleton variant="rounded" sx={{ height: 60, borderRadius: 3 }} />
					<Skeleton variant="rounded" sx={{ height: 60, borderRadius: 3 }} />
				</Stack>
			</Layout>
		);
	}

	return (
		<Box
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			height="100%"
		>
			<AppBar sx={(theme) => ({ backgroundColor: theme.palette.common.white })}>
				<Toolbar
					variant="dense"
					sx={{ alignItems: "center", justifyContent: "space-between" }}
				>
					<Link
						to={APP_ROUTES.RECEIPTS.LIST}
						style={{ position: "relative", display: "flex" }}
					>
						<img
							src="/logoNoticiaCropped.png"
							width={100}
							height={30}
							alt="Logo Noticia"
						/>
					</Link>
					<Box display="flex" alignItems="center">
						<Skeleton
							variant="rounded"
							height={26}
							width={66}
							sx={{ borderRadius: 4 }}
						/>
						<Box ml={2} mr={1}>
							<Skeleton variant="circular" height={32} width={32} />
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			{children && (
				<>
					<Box flexGrow={1} mt={11.5} width="100%">
						<Container
							maxWidth="xs"
							sx={{ display: "flex", flexDirection: "column", height: "100%" }}
						>
							{children}
						</Container>
					</Box>
					<Footer />
				</>
			)}
		</Box>
	);
};

export default LoadingLayout;
