import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { isNull } from "lodash";

import * as constants from "~/constants";
import { userQueryKey } from "~/hooks/auth/userQueryKey";
import { type ApiError, UsersController, type Wallet } from "~/services/api";

const useWallet = (
	options: Partial<UseQueryOptions<Wallet, ApiError>> = {},
) => {
	const { enabled, ...restOptions } = options;

	const { data, isLoading, isFetched, ...request } = useQuery({
		queryKey: userQueryKey.GET_WALLET,
		queryFn: () => UsersController.getAuthenticatedUserWallet(),
		enabled:
			!isNull(localStorage.getItem(constants.localStorage.token)) && enabled,
		...restOptions,
	});

	return {
		wallet: data,
		isWalletLoading: isLoading,
		isWalletFetched: isFetched,
		...request,
	};
};

export default useWallet;
