import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "wouter";

import { APP_ROUTES } from "~/constants";

interface Props {
	isLoading?: boolean;
	isLogged?: boolean;
	onLogout?: () => void;
}

const ProfileButton = ({ isLoading, isLogged, onLogout }: Props) => {
	if (isLoading) {
		return <Skeleton variant="circular" height={32} width={32} />;
	}

	if (isLogged) {
		return (
			<Tooltip title="Se déconnecter">
				<IconButton
					onClick={onLogout}
					data-testid="logout-button"
					sx={(theme) => ({
						height: 27,
						width: 27,
						backgroundColor: theme.palette.primary.dark,

						":hover": {
							backgroundColor: theme.palette.primary.dark,
						},
					})}
				>
					<LogoutIcon
						color="primary"
						sx={(theme) => ({
							ml: 0.25,
							fontSize: theme.typography.body3_medium.fontSize,
							color: theme.palette.common.white,
						})}
					/>
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<Tooltip title="Se connecter">
			<IconButton
				component={Link}
				to={APP_ROUTES.AUTH.AUTHENTICATE}
				data-testid="profile-button"
				sx={{ p: 0 }}
			>
				<AccountCircleIcon
					color="primary"
					sx={(theme) => ({
						height: 32,
						width: 32,
						fontSize: theme.typography.h3_bold.fontSize,
						color: theme.palette.primary.dark,
					})}
				/>
			</IconButton>
		</Tooltip>
	);
};

export default ProfileButton;
